import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import { EditorProvider } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import CharacterCount from "@tiptap/extension-character-count";
import { useEffect, useState, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useUserInfo } from "../hooks/useUserInfo";
import { useParams } from "react-router-dom";
import { encryptSymmetricString } from "../crypto/utils";
import { useDebouncedCallback } from "use-debounce";
import {
  setNoteChecksum,
  setNoteHtml,
  setOutOfSync,
} from "../redux/draftStore";
import Placeholder from "@tiptap/extension-placeholder";
import { setUnauthedNoteData } from "../utils/unauthedLocalStorage";

const extensions = [
  // @ts-ignore
  TextStyle.configure({ type: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
  CharacterCount.configure(),
  Placeholder.configure({
    placeholder: "Scratchpad: won't count towards word count",
  }),
];

const UPDATE_NOTE_HTML_MUTATION = gql`
  mutation updateNoteHtml(
    $draftID: String!
    $encryptedNoteHtml: String!
    $oldNoteChecksum: String
  ) {
    updateNoteHtml(
      request: {
        draftID: $draftID
        encryptedNoteHtml: $encryptedNoteHtml
        oldNoteChecksum: $oldNoteChecksum
      }
    ) {
      newNoteChecksum
    }
  }
`;

export default function NoteEditor() {
  const dispatch = useDispatch();

  const [updateDraftHtml] = useMutation(UPDATE_NOTE_HTML_MUTATION, {
    onCompleted: (data) => {
      if (data.updateNoteHtml) {
        dispatch(setNoteChecksum(data.updateNoteHtml.newNoteChecksum));
      }
    },
    onError: (e) => {
      const errorCodes = e.graphQLErrors.map((error) => error.extensions?.code);
      if (errorCodes.includes("CHECKSUM_MISMATCH")) {
        dispatch(setOutOfSync());
      }
    },
  });
  const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);
  const noteHtml = useSelector((state: RootState) => state.draft.noteHtml);
  const noteChecksum = useSelector(
    (state: RootState) => state.draft.noteChecksum
  );
  const loaded = useSelector((state: RootState) => state.draft.loaded);
  const [localHtml, setLocalHtml] = useState(noteHtml);
  const { decryptionKey, isLoggedIn } = useUserInfo();
  const { draftID } = useParams();

  const saveEncryptedNoteHtml = useCallback(async () => {
    if (decryptionKey && localHtml && draftID && initialLoadCompleted) {
      const encryptedNoteHtml = encryptSymmetricString({
        secretKey: decryptionKey,
        decryptedPayload: localHtml,
      });
      await updateDraftHtml({
        variables: {
          draftID,
          encryptedNoteHtml,
          oldNoteChecksum: noteChecksum,
        },
      });
    }
  }, [
    decryptionKey,
    draftID,
    initialLoadCompleted,
    localHtml,
    noteChecksum,
    updateDraftHtml,
  ]);

  const debouncedSaveEncryptedNoteHtml = useDebouncedCallback(
    saveEncryptedNoteHtml,
    300
  );

  useEffect(() => {
    if (loaded && !initialLoadCompleted) {
      setLocalHtml(noteHtml);
      setInitialLoadCompleted(true);
    }
  }, [noteHtml, initialLoadCompleted, loaded, localHtml]);

  useEffect(() => {
    if (!isLoggedIn) {
      setUnauthedNoteData(noteHtml);
    }
  }, [isLoggedIn, noteHtml]);

  if (!loaded) {
    return null;
  }

  return (
    <div
      className="flex flex-col py-4 px-6 overflow-y-auto relative"
      id="note-editor-container" // this ID is used in index.css
      style={{ flex: "1 1 auto" }}
    >
      <EditorProvider
        slotBefore={null}
        slotAfter={<div style={{ minHeight: 500, height: 500 }} />}
        extensions={extensions}
        content={noteHtml}
        editorProps={{
          attributes: {
            className: "h-full",
          },
        }}
        onUpdate={async ({ editor }) => {
          const html = editor.getHTML();
          setLocalHtml(html);
          dispatch(setNoteHtml({ noteHtml: html }));
          if (isLoggedIn) {
            debouncedSaveEncryptedNoteHtml();
          }
        }}
      >
        {null}
      </EditorProvider>
    </div>
  );
}
