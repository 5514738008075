import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { setDraftNotLoaded, setInitialData } from "../redux/draftStore";
import { useUserInfo } from "./useUserInfo";
import { decryptSymmetricString } from "../crypto/utils";
import {
  getUnauthedDraftData,
  getUnauthedNoteData,
} from "../utils/unauthedLocalStorage";

const GET_DRAFT = gql`
  query getDraft($draftID: String!) {
    getDraft(request: { draftID: $draftID }) {
      encryptedTitle
      encryptedDraftHtml
      encryptedNoteHtml
      draftChecksum
      noteChecksum
      titleChecksum
      noteDraftSplit
      draftPlaylist
      playlistName
    }
  }
`;

const GET_MOST_RECENTLY_EDITED_DRAFT = gql`
  query GetMostRecentlyEditedDraftHook {
    getMostRecentlyEditedDraft {
      draftID
    }
  }
`;

export function useCurrentDraft() {
  const { draftID, roomID } = useParams();
  const { decryptionKey, isLoggedIn } = useUserInfo();
  const navigate = useNavigate();
  let draftTitle = useSelector((state: RootState) => state.draft.title);
  let draftLoaded = useSelector((state: RootState) => state.draft.loaded);
  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [getMostRecentlyEditedDraft] = useLazyQuery(
    GET_MOST_RECENTLY_EDITED_DRAFT,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [getDraft, { data }] = useLazyQuery(GET_DRAFT, {
    onCompleted: (data) => {
      setDataLoaded(true);
    },
    onError: async (e) => {
      // if draft doesn't exist, try to navigate to the most recently edited draft
      const mostRecentlyEditedDraftResponse =
        await getMostRecentlyEditedDraft();
      if (
        mostRecentlyEditedDraftResponse.data.getMostRecentlyEditedDraft.draftID
      ) {
        navigate(
          `/room/${roomID}/draft/${mostRecentlyEditedDraftResponse.data.getMostRecentlyEditedDraft.draftID}`
        );
      } else {
        navigate(`/room/${roomID}`);
      }
    },
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    if (!draftLoaded && isLoggedIn) {
      getDraft({ variables: { draftID } });
    }
  }, [draftID, draftLoaded, getDraft, isLoggedIn]);
  useEffect(() => {
    if (isLoggedIn && decryptionKey && dataLoaded) {
      const decryptedTitle = decryptSymmetricString({
        secretKey: decryptionKey,
        encryptedPayload: data.getDraft.encryptedTitle,
      });
      const decryptedDraftHtml = decryptSymmetricString({
        secretKey: decryptionKey,
        encryptedPayload: data.getDraft.encryptedDraftHtml,
      });
      const decryptedNoteHtml = decryptSymmetricString({
        secretKey: decryptionKey,
        encryptedPayload: data.getDraft.encryptedNoteHtml,
      });

      dispatch(
        setInitialData({
          draftHtml: decryptedDraftHtml,
          title: decryptedTitle,
          noteHtml: decryptedNoteHtml,
          noteChecksum: data.getDraft.noteChecksum,
          draftChecksum: data.getDraft.draftChecksum,
          titleChecksum: data.getDraft.titleChecksum,
          noteDraftSplit: data.getDraft.noteDraftSplit,
          draftPlaylist: data.getDraft.draftPlaylist,
          playlistName: data.getDraft.playlistName,
        })
      );
    }
  }, [data, dataLoaded, decryptionKey, dispatch, draftID, isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) {
      const unauthedDraftData = getUnauthedDraftData();
      const unauthedNoteData = getUnauthedNoteData();
      dispatch(
        setInitialData({
          draftHtml: unauthedDraftData,
          title: "Your draft",
          noteHtml: unauthedNoteData,
          noteChecksum: null,
          draftChecksum: null,
          titleChecksum: null,
          noteDraftSplit: 0.3,
          draftPlaylist: null,
          playlistName: null,
        })
      );
    }
  }, [dispatch, isLoggedIn]);
  //   if (!decryptionKey) {
  //     return { draftTitle: null, draftLoaded: false };
  //   }
  return { draftTitle, draftLoaded };
}
