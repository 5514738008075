import { useMediaQuery } from "react-responsive";
import { SundialHistoryChart } from "./SundialHistoryChart";
import { Tabs, Tab } from "@nextui-org/react";
import { Suspense, useState } from "react";
import FeedProvider from "../providers/FeedProvider";
import ActivityFeed from "./ActivityFeed";
import { Global, Profile2User } from "iconsax-react";

export default function HomeFeed() {
  const [showGlobalFeed, setShowGlobalFeed] = useState(true);
  // const isMobile = useMediaQuery({
  //   query: "(max-width: 980px)",
  // });
  return (
    <div className="flex flex-col items-center" style={{ width: "100vw" }}>
      <div style={{ marginTop: 10 }}>
        {/* {!isMobile && (
          <div
            style={{
              position: "absolute",
              left: 20,
              top: 20,
              height: 300,
              width: 300,
              marginLeft: -50,
            }}
          >
            <SundialHistoryChart />
          </div>
        )} */}
        <Tabs
          aria-label="Feed options"
          onSelectionChange={async (key) => {
            setShowGlobalFeed(key === "global");
          }}
        >
          <Tab
            key="global"
            title={
              <div className="flex flex-row items-center">
                <div style={{ marginRight: 4 }}>
                  <Global size={12} />
                </div>
                <p className="font-sans">Global</p>
              </div>
            }
          />
          <Tab
            key="following"
            title={
              <div className="flex flex-row items-center">
                <div style={{ marginRight: 4 }}>
                  <Profile2User size={12} />
                </div>
                <p className="font-sans">Following</p>
              </div>
            }
          />
        </Tabs>
      </div>
      <Suspense fallback={<p>loading...</p>}>
        <FeedProvider global={showGlobalFeed}>
          <ActivityFeed maxHeight="calc(100dvh - 110px)">
            <div className="mt-2">
              <p className="font-sans">
                Follow some writers to see their activity here!
              </p>
            </div>
          </ActivityFeed>
        </FeedProvider>
      </Suspense>
    </div>
  );
}
