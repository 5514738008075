export function getUnauthedUserHash() {
  const currentHash = localStorage.getItem("unauthedUserHash");

  // check if currentHash is a number between 0 and 1000000
  if (
    currentHash &&
    !isNaN(Number(currentHash)) &&
    Number(currentHash) >= 0 &&
    Number(currentHash) < 1000000
  ) {
    return Number(currentHash);
  }

  // random number from 0 to 1000000
  const newHash = Math.floor(Math.random() * 1000000).toString();
  localStorage.setItem("unauthedUserHash", newHash);
  return Number(newHash);
}

export function clearUnauthedUserHash() {
  localStorage.removeItem("unauthedUserHash");
}

export function getUnauthedDraftData() {
  const currentDraftData = localStorage.getItem("unauthedDraftData");
  if (currentDraftData) {
    return currentDraftData;
  }
  return null;
}

export function setUnauthedDraftData(draftData: string | null) {
  if (draftData) {
    localStorage.setItem("unauthedDraftData", draftData);
  } else {
    localStorage.removeItem("unauthedDraftData");
  }
}

export function clearUnauthedDraftData() {
  localStorage.removeItem("unauthedDraftData");
}

export function getUnauthedNoteData() {
  const currentNoteData = localStorage.getItem("unauthedNoteData");
  if (currentNoteData) {
    return currentNoteData;
  }
  return null;
}

export function setUnauthedNoteData(noteData: string | null) {
  if (noteData) {
    localStorage.setItem("unauthedNoteData", noteData);
  } else {
    localStorage.removeItem("unauthedNoteData");
  }
}

export function clearUnauthedNoteData() {
  localStorage.removeItem("unauthedNoteData");
}
