export function convertSecondsToString(
  seconds: number,
  options: { showSeconds?: boolean } = { showSeconds: true }
) {
  // convert to hours:minutes:seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  // if hours is 0, don't display it
  const hoursString = hours ? `${hours}` : "";
  const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const secondsString =
    remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
  if (!options.showSeconds) return `${hoursString}:${minutesString}`;
  return `${hoursString}${hoursString && ":"}${minutesString}:${secondsString}`;
}

export function formatTimeDifference(endTime: Date, startTime: Date) {
  const timeDifference = endTime.getTime() - startTime.getTime();
  return convertSecondsToString(timeDifference / 1000);
}

export function parseHoursMinutes(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return { hours, minutes };
}
