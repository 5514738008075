import { gql, useLazyQuery } from "@apollo/client";
import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { useCallback } from "react";
import { clearUserInfo } from "../redux/userStore";
import { useDispatch } from "react-redux";
import { setExportKey } from "../crypto/utils";
import { useNavigate } from "react-router-dom";
import { useUserInfo } from "../hooks/useUserInfo";

const LOGOUT_QUERY = gql`
  query Logout {
    logout
  }
`;

enum DropdownKeys {
  Logout = "Logout",
  Profile = "Profile",
  Settings = "Settings",
}

export default function ProfileMenu() {
  const { username, randomUsername, profilePhotoDownloadURL } = useUserInfo();
  const [logoutQuery] = useLazyQuery(LOGOUT_QUERY, { fetchPolicy: "no-cache" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = useCallback(async () => {
    await logoutQuery();
    dispatch(clearUserInfo());
    setExportKey("");
    navigate("/login");
  }, [dispatch, logoutQuery, navigate]);
  return (
    <Dropdown className="font-sans">
      <DropdownTrigger>
        <Avatar
          className="cursor-pointer"
          src={profilePhotoDownloadURL ?? ""}
          showFallback
        />
      </DropdownTrigger>
      <DropdownMenu
        aria-labelledby="profile-menu"
        topContent={
          <div
            style={{
              color: "#7194A8",
              paddingLeft: 8,
              borderBottomWidth: 1,
              borderBottomColor: "#d4d4d8",
              paddingBottom: 4,
            }}
            className="font-sans"
          >
            <p>{username ?? `${randomUsername} (guest)`}</p>
          </div>
        }
        onAction={(key) => {
          if (key === DropdownKeys.Logout) {
            logout();
          } else if (key === DropdownKeys.Profile) {
            navigate(`/writer/${username}`);
          } else if (key === DropdownKeys.Settings) {
            navigate("/settings");
          }
        }}
      >
        <DropdownItem key={DropdownKeys.Profile}>Profile</DropdownItem>
        <DropdownItem key={DropdownKeys.Settings}>Settings</DropdownItem>
        <DropdownItem key={DropdownKeys.Logout}>Logout</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
