import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";
import { USER_SETTINGS } from "../lib/userSettings";
import { useUserInfo } from "./useUserInfo";

const GET_USER_SETTINGS = gql`
  query GetUserSettings {
    getUserSettings {
      autoRecord
      autoSave
    }
  }
`;

export function useUserSettings() {
  const userID = useSelector((state: RootState) => state.user.userID);
  const { isLoggedIn } = useUserInfo();
  const [
    getUserSettings,
    { loading: userSettingsLoading, data: userSettingsData },
  ] = useLazyQuery(GET_USER_SETTINGS, {
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    if (userID && isLoggedIn) {
      getUserSettings();
    }
  }, [getUserSettings, userID, isLoggedIn]);

  const userSettingsOrDefaults = useMemo(() => {
    if (!userSettingsLoading) {
      return Object.fromEntries(
        Object.keys(USER_SETTINGS).map((settingName) => {
          const value = userSettingsData?.getUserSettings?.[settingName];
          const defaultValue = USER_SETTINGS[settingName].defaultValue;
          return [settingName, value ?? defaultValue];
        })
      );
    }
  }, [userSettingsData, userSettingsLoading]);

  return {
    userSettingsLoading,
    userSettingsOrDefaults,
  };
}
