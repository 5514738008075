import { gql, useQuery } from "@apollo/client";

import HomepageHeader from "../components/HomepageHeader";

import { setExportKey } from "../crypto/utils";
import RefreshedHome from "./RefreshedHome";
import { useMediaQuery } from "react-responsive";
import HomeFeed from "../components/HomeFeed";
import RoomsGrid from "../components/RoomsGrid";
import { useEffect, useMemo, useState } from "react";

const CHECK_LOGGED_IN = gql`
  query CheckLoggedIn {
    isLoggedIn
  }
`;

// const SEED_DATA = gql`
//   mutation SeedData {
//     seedData {
//       success
//     }
//   }
// `;

function Home() {
  const { data, loading } = useQuery(CHECK_LOGGED_IN, {
    fetchPolicy: "no-cache",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 980px)",
  });

  const isLoggedIn = useMemo(() => {
    return data && data.isLoggedIn;
  }, [data]);

  useEffect(() => {
    if (!isLoggedIn && !loading) {
      setExportKey("");
    }
  }, [isLoggedIn, loading]);

  if (loading) {
    return <p>loading...</p>;
  }

  return (
    <div>
      <div>
        <HomepageHeader />
      </div>
      <div className="flex flex-row w-full justify-center relative">
        {isMobile ? (
          <HomeFeed />
        ) : (
          <>
            <RoomsGrid />
            {isLoggedIn && <HomeFeed />}
          </>
        )}
      </div>
    </div>
  );
}

export default Home;
