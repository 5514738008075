import { AddSquare, Timer1, User } from "iconsax-react";
import EarthBackground from "../assets/earth-background.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoomMetadata } from "../gql/graphql";
import { gql, useQuery } from "@apollo/client";
import { formatTimeDifference } from "../utils/string";

const GET_ROOM_LIST = gql`
  query GetRoomList {
    getRoomList {
      publicRooms {
        roomID
        name
        timeLeftSeconds
        endTime
        imageDownloadURL
        private
        permanent
        ownerID
        numUsersInRoom
      }
      ownedRooms {
        roomID
        name
        timeLeftSeconds
        endTime
        imageDownloadURL
        private
        permanent
        ownerID
        numUsersInRoom
      }
    }
  }
`;

const GET_MOST_RECENTLY_EDITED_DRAFT = gql`
  query GetMostRecentlyEditedDraft {
    getMostRecentlyEditedDraft {
      draftID
    }
  }
`;

export default function RoomsGrid() {
  const [createNewHovered, setCreateNewHovered] = useState(false);
  const { data, loading } = useQuery(GET_ROOM_LIST, {
    fetchPolicy: "no-cache",
  });
  const { data: mostRecentlyEditedDraftData } = useQuery(
    GET_MOST_RECENTLY_EDITED_DRAFT,
    {
      fetchPolicy: "no-cache",
    }
  );
  const navigate = useNavigate();

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="w-full"
      style={{
        marginTop: 20,
        marginLeft: 64,
        maxHeight: "calc(100dvh - 110px)",
        overflowY: "auto",
      }}
    >
      {/* Public rooms section */}
      <div className="flex flex-col items-start">
        <div>
          <p className="font-sans text-xl" style={{ fontWeight: "bold" }}>
            Public rooms
          </p>
        </div>
        <div style={{ marginTop: 20 }} className="flex flex-wrap gap-4">
          {loading ? (
            <p>Loading rooms...</p>
          ) : (
            data?.getRoomList.publicRooms.map((room: RoomMetadata) => (
              <div
                key={room.roomID}
                className="flex flex-col items-center justify-end cursor-pointer"
                style={{
                  width: 200,
                  height: 200,
                  backgroundImage: `url(${
                    room.imageDownloadURL || EarthBackground
                  })`,
                  backgroundSize: "cover",
                  borderRadius: 10,
                }}
                onClick={() => {
                  navigate(
                    `/room/${room.roomID}${
                      mostRecentlyEditedDraftData?.getMostRecentlyEditedDraft
                        .draftID
                        ? `/draft/${mostRecentlyEditedDraftData.getMostRecentlyEditedDraft.draftID}`
                        : ""
                    }`
                  );
                }}
              >
                <div
                  className="flex flex-col blurred-div"
                  style={{
                    margin: 10,
                    borderRadius: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    width: 180,
                  }}
                >
                  <p
                    className="font-sans text-white"
                    style={{ fontWeight: "bold", fontSize: 12 }}
                  >
                    {room.name}
                  </p>
                  <div className="flex flex-row justify-between">
                    {room.endTime && new Date(room.endTime) > currentTime ? (
                      <div className="flex flex-row items-center">
                        <div style={{ marginRight: 5 }}>
                          <Timer1 color="white" size={10} />
                        </div>
                        <p
                          className="font-sans"
                          style={{ fontSize: 8, color: "white" }}
                        >
                          {formatTimeDifference(
                            new Date(room.endTime),
                            currentTime
                          )}
                        </p>
                      </div>
                    ) : (
                      <div />
                    )}
                    <div className="flex flex-row items-center">
                      <div style={{ marginRight: 2 }}>
                        <p
                          className="font-sans"
                          style={{ color: "white", fontSize: 8 }}
                        >
                          {room.numUsersInRoom}
                        </p>
                      </div>
                      <User color="white" size={10} />
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      {/* Owned rooms section */}
      <div className="flex flex-col items-start" style={{ marginTop: 50 }}>
        <div>
          <p className="font-sans text-xl" style={{ fontWeight: "bold" }}>
            Your rooms
          </p>
        </div>
        <div style={{ marginTop: 20 }} className="flex flex-wrap gap-4">
          {loading ? (
            <p>Loading rooms...</p>
          ) : (
            data?.getRoomList.ownedRooms.map((room: RoomMetadata) => (
              <div
                key={room.roomID}
                className="flex flex-col items-center justify-end cursor-pointer"
                style={{
                  width: 200,
                  height: 200,
                  backgroundImage: `url(${
                    room.imageDownloadURL || EarthBackground
                  })`,
                  backgroundSize: "cover",
                  borderRadius: 10,
                }}
                onClick={() => {
                  navigate(
                    `/room/${room.roomID}${
                      mostRecentlyEditedDraftData?.getMostRecentlyEditedDraft
                        .draftID
                        ? `/draft/${mostRecentlyEditedDraftData.getMostRecentlyEditedDraft.draftID}`
                        : ""
                    }`
                  );
                }}
              >
                {/* Room details */}
                <div
                  className="flex flex-col blurred-div"
                  style={{
                    margin: 10,
                    borderRadius: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    width: 180,
                  }}
                >
                  <p
                    className="font-sans text-white"
                    style={{ fontWeight: "bold", fontSize: 12 }}
                  >
                    {room.name}
                  </p>
                  <div className="flex flex-row justify-between">
                    {room.endTime && new Date(room.endTime) > currentTime ? (
                      <div className="flex flex-row items-center">
                        <div style={{ marginRight: 5 }}>
                          <Timer1 color="white" size={10} />
                        </div>
                        <p
                          className="font-sans"
                          style={{ fontSize: 8, color: "white" }}
                        >
                          {formatTimeDifference(
                            new Date(room.endTime),
                            currentTime
                          )}
                        </p>
                      </div>
                    ) : (
                      <div />
                    )}
                    <div className="flex flex-row items-center">
                      <div style={{ marginRight: 2 }}>
                        <p
                          className="font-sans"
                          style={{ color: "white", fontSize: 8 }}
                        >
                          {room.numUsersInRoom}
                        </p>
                      </div>
                      <User color="white" size={10} />
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}

          {/* Create new room button */}
          <div
            onMouseEnter={() => setCreateNewHovered(true)}
            onMouseLeave={() => setCreateNewHovered(false)}
            className="flex flex-col items-center justify-center"
            style={{
              width: 200,
              height: 200,
              backgroundColor: createNewHovered ? "#7194A8" : "#e0e0e0",
              borderRadius: 10,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/create-room");
            }}
          >
            <AddSquare color={"white"} size={80} style={{ strokeWidth: 0.5 }} />
            <p
              className="font-sans"
              style={{
                color: "white",
                fontSize: 12,
              }}
            >
              Create new room
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
