import {
  Avatar,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Input,
} from "@nextui-org/react";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { encryptSymmetricString, setExportKey } from "../crypto/utils";
import { useUserInfo } from "../hooks/useUserInfo";
import { useNavigate, useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";
import {
  setDraftTitle as reduxSetDraftTitle,
  setOutOfSync,
  setTitleChecksum,
} from "../redux/draftStore";
import { RootState } from "../redux/store";
import RecordingStatus from "./RecordingStatus";
import { useCurrentActivity } from "../hooks/useCurrentActivity";
import { useCreateActivity } from "../hooks/useCreateActivity";
import Logo from "../assets/logo.png";
import EarthBackground from "../assets/earth-background.png";
import { clearUserInfo } from "../redux/userStore";
import { RoomPresets } from "../gql/graphql";
import { Document, Home3 } from "iconsax-react";
import DraftSelectModal from "./DraftSelectModal";
import { getDraftPath } from "../utils/urlPaths";
import RoomTimer from "./RoomTimer";
import { Socket } from "socket.io-client";

const UPDATE_DRAFT_TITLE_MUTATION = gql`
  mutation updateEncryptedDraftTitle(
    $draftID: String!
    $encryptedTitle: String!
    $oldTitleChecksum: String
  ) {
    updateTitle(
      request: {
        draftID: $draftID
        encryptedTitle: $encryptedTitle
        oldTitleChecksum: $oldTitleChecksum
      }
    ) {
      newTitleChecksum
    }
  }
`;

const LOGOUT_QUERY = gql`
  query Logout {
    logout
  }
`;

enum DropdownKeys {
  Logout = "Logout",
  Profile = "Profile",
  Settings = "Settings",
}

enum IconDropdownKeys {
  Home = "Home",
  CreateNew = "CreateNew",
  SelectDraft = "SelectDraft",
  DeleteRoom = "DeleteRoom",
}

const CREATE_DRAFT = gql`
  mutation CreateDraft {
    createDraft
  }
`;

const GET_ROOM_DATA = gql`
  query getRoomDataDocumentEditor($roomID: String!) {
    getRoomData(request: { roomID: $roomID }) {
      roomID
      prompt
      timeLeftSeconds
      imageDownloadURL
      name
      ownerID
      presetID
    }
  }
`;

const DELETE_ROOM = gql`
  mutation DeleteRoom($roomID: String!) {
    deleteRoom(request: { roomID: $roomID }) {
      success
    }
  }
`;

const UPDATE_ROOM_NAME = gql`
  mutation UpdateRoomName($name: String!, $roomID: String!) {
    updateRoomName(request: { name: $name, roomID: $roomID }) {
      success
    }
  }
`;

function UnauthenticatedDocumentEditorHeader({
  socket,
}: {
  socket: Socket | null;
}) {
  const { username, userID, profilePhotoDownloadURL, decryptionKey } =
    useUserInfo();
  const navigate = useNavigate();

  const [logoutQuery] = useLazyQuery(LOGOUT_QUERY, { fetchPolicy: "no-cache" });
  const { draftID, roomID } = useParams();

  const [createDraft] = useMutation(CREATE_DRAFT);
  const [deleteRoom] = useMutation(DELETE_ROOM);
  const [lastDraftID, setLastDraftID] = useState<string | null>(null);
  const [roomName, setRoomName] = useState<string | null>(null);
  useEffect(() => {
    if (!lastDraftID && draftID) {
      setLastDraftID(draftID);
    }
  }, [draftID, lastDraftID]);

  // if user is navigating between drafts, blow everything away
  useEffect(() => {
    if (draftID && lastDraftID && draftID !== lastDraftID) {
      navigate(0); // equivalent to window.location.reload()
    }
  }, [draftID, lastDraftID, navigate]);

  const { data: roomData } = useQuery(GET_ROOM_DATA, {
    variables: { roomID },
    skip: !roomID,
    onCompleted: (data) => {
      setRoomName(data.getRoomData.name);
    },
  });

  const isPresetRoom = useMemo(() => {
    return !!roomData?.getRoomData?.presetID;
  }, [roomData?.getRoomData?.presetID]);

  const [updateRoomName] = useMutation(UPDATE_ROOM_NAME);

  const debouncedUpdateRoomName = useDebouncedCallback(updateRoomName, 300);

  const logoSrc = useMemo(() => {
    if (roomID === RoomPresets.TheEveryoneRoom) {
      return EarthBackground;
    }
    return roomData?.getRoomData?.imageDownloadURL || Logo;
  }, [roomData?.getRoomData?.imageDownloadURL, roomID]);

  const savedDraftTitle = useSelector((state: RootState) => state.draft.title);
  const titleChecksum = useSelector(
    (state: RootState) => state.draft.titleChecksum
  );
  const [updateEncryptedDraftTitle] = useMutation(UPDATE_DRAFT_TITLE_MUTATION, {
    onCompleted: (data) => {
      if (data.updateTitle) {
        dispatch(setTitleChecksum(data.updateTitle.newTitleChecksum));
      }
    },
    onError: (e) => {
      const errorCodes = e.graphQLErrors.map((error) => error.extensions?.code);
      if (errorCodes.includes("CHECKSUM_MISMATCH")) {
        dispatch(setOutOfSync());
      }
    },
  });
  const [draftTitle, setDraftTitle] = useState("");
  const [allDraftsModalOpen, setAllDraftsModalOpen] = useState(false);
  const currentActivityData = useCurrentActivity();
  const { createActivityIfNoCurrent } = useCreateActivity();
  const dispatch = useDispatch();
  const logout = useCallback(async () => {
    await logoutQuery();
    dispatch(clearUserInfo());
    setExportKey("");
    navigate("/login");
  }, [dispatch, logoutQuery, navigate]);

  const encryptAndUpdateDraftTitle = useCallback(async () => {
    if (!decryptionKey) {
      return;
    }
    const encryptedTitle = encryptSymmetricString({
      secretKey: decryptionKey,
      decryptedPayload: draftTitle,
    });
    await updateEncryptedDraftTitle({
      variables: {
        draftID,
        encryptedTitle,
        oldTitleChecksum: titleChecksum,
      },
    });
  }, [
    decryptionKey,
    draftID,
    draftTitle,
    titleChecksum,
    updateEncryptedDraftTitle,
  ]);

  useEffect(() => {
    if (savedDraftTitle && savedDraftTitle !== draftTitle) {
      setDraftTitle(savedDraftTitle);
    }
  }, [draftTitle, savedDraftTitle]);

  const debouncedEncryptAndUpdateDraftTitle = useDebouncedCallback(
    encryptAndUpdateDraftTitle,
    300
  );

  const [inputWidth, setInputWidth] = useState(0);
  const hiddenSpanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (hiddenSpanRef.current) {
      const width = hiddenSpanRef.current.getBoundingClientRect().width;
      setInputWidth(width); // Add 4px for a little buffer
    }
  }, [roomName]);

  const isRoomOwner = useMemo(() => {
    return userID === roomData?.getRoomData?.ownerID;
  }, [userID, roomData?.getRoomData?.ownerID]);

  return (
    <>
      <div
        className="w-full flex flex-row py-2 items-center border-b border-solid border-black px-3 fixed"
        style={{ backgroundColor: "white", zIndex: 1000 }}
      >
        <a href="/">
          <img
            src={logoSrc}
            alt="Logo"
            style={{
              width: "40px",
              height: "40px",
              objectFit: "cover",
              objectPosition: "center",
              borderRadius: 10,
            }}
          />
        </a>
        <div className="flex-1" style={{ paddingLeft: 12 }}>
          <div className="flex flex-row items-center">
            <div className="inline-block flex-shrink">
              {roomName && (
                <div className="inline-block relative">
                  <input
                    type="text"
                    placeholder="Room name"
                    value={roomName}
                    readOnly={!isRoomOwner || isPresetRoom}
                    onChange={(e) => {
                      setRoomName(e.target.value);
                      debouncedUpdateRoomName({
                        variables: {
                          name: e.target.value,
                          roomID,
                        },
                      });
                    }}
                    style={{
                      color: "#456577",
                      width: `${inputWidth}px`,
                    }}
                    className="font-sans font-semibold text-lg bg-transparent shadow-none outline-none"
                  />
                  <span
                    ref={hiddenSpanRef}
                    className="absolute opacity-0 pointer-events-none whitespace-pre whitespace-nowrap font-sans font-semibold text-lg"
                  >
                    {roomName}
                  </span>
                </div>
              )}
            </div>
            {roomName && (
              <div className="flex flex-row items-center px-1">
                <p style={{ fontSize: 30, color: "#456577" }}>·</p>
              </div>
            )}
            <div className="flex-1">
              <Input
                placeholder="Document Title"
                classNames={{
                  input: ["font-sans font-semibold text-lg"],
                  inputWrapper: [
                    "bg-transparent",
                    "shadow-none",
                    "data-[hover=true]:bg-transparent",
                    "group-data-[focus=true]:bg-transparent",
                    "h-[48px]",
                    "py-[5px]",
                    "w-full",
                    "px-0",
                    "min-h-unit-0",
                  ],
                  innerWrapper: ["pb-0"],
                }}
                value={draftTitle}
                isReadOnly={true}
              />
            </div>
            {roomID && !isPresetRoom && (
              <div style={{ marginRight: 16 }}>
                <RoomTimer socket={socket} />
              </div>
            )}
            <div style={{ marginRight: 16 }}>
              <RecordingStatus
                currentActivityData={currentActivityData}
                createActivityIfNoCurrent={createActivityIfNoCurrent}
              />
            </div>
          </div>
        </div>
        <a href="/signup">
          <Button variant="bordered" color="primary" className="font-sans">
            Create account to save your draft
          </Button>
        </a>
      </div>
      <div style={{ minHeight: 57 }}></div>
    </>
  );
}

export default UnauthenticatedDocumentEditorHeader;
