const ADJECTIVE_LIST = [
  "admirable",
  "adventurous",
  "affectionate",
  "agreeable",
  "amazing",
  "ambitious",
  "amiable",
  "angelic",
  "appreciative",
  "artistic",
  "assertive",
  "astonishing",
  "attentive",
  "authentic",
  "awesome",
  "balanced",
  "beautiful",
  "blissful",
  "bold",
  "brave",
  "brilliant",
  "bubbly",
  "calm",
  "capable",
  "careful",
  "caring",
  "charming",
  "cheerful",
  "clever",
  "compassionate",
  "confident",
  "considerate",
  "consistent",
  "cooperative",
  "courageous",
  "courteous",
  "creative",
  "curious",
  "daring",
  "dedicated",
  "delightful",
  "determined",
  "diligent",
  "diplomatic",
  "dynamic",
  "eager",
  "easygoing",
  "efficient",
  "elegant",
  "empathetic",
  "encouraging",
  "energetic",
  "enthusiastic",
  "ethical",
  "exceptional",
  "exciting",
  "experienced",
  "fabulous",
  "fair",
  "faithful",
  "fantastic",
  "fearless",
  "flexible",
  "forgiving",
  "friendly",
  "fun",
  "generous",
  "genius",
  "gentle",
  "genuine",
  "glorious",
  "graceful",
  "gracious",
  "great",
  "happy",
  "hardworking",
  "helpful",
  "honest",
  "hopeful",
  "hospitable",
  "humble",
  "imaginative",
  "impressive",
  "incredible",
  "independent",
  "inspirational",
  "intelligent",
  "inventive",
  "joyful",
  "jovial",
  "just",
  "kind",
  "knowledgeable",
  "likable",
  "lively",
  "lovable",
  "lovely",
  "loyal",
  "magnificent",
  "mindful",
  "motivated",
  "noble",
  "optimistic",
  "organized",
  "passionate",
  "patient",
  "peaceful",
  "persistent",
  "playful",
  "polite",
  "positive",
  "powerful",
  "practical",
  "proactive",
  "productive",
  "protective",
  "punctual",
  "radiant",
  "rational",
  "reliable",
  "remarkable",
  "resilient",
  "respectful",
  "responsible",
  "resourceful",
  "sensible",
  "sincere",
  "smart",
  "sociable",
  "spectacular",
  "spirited",
  "strong",
  "supportive",
  "sweet",
  "talented",
  "thoughtful",
  "tolerant",
  "trustworthy",
  "truthful",
  "understanding",
  "unique",
  "uplifted",
  "vibrant",
  "victorious",
  "warm",
  "wise",
  "wonderful",
  "worthy",
  "youthful",
  "zealous",
];

const ANIMAL_LIST = [
  "Aardvark",
  "Albatross",
  "Alligator",
  "Alpaca",
  "Ant",
  "Anteater",
  "Antelope",
  "Ape",
  "Armadillo",
  "Donkey",
  "Baboon",
  "Badger",
  "Barracuda",
  "Bat",
  "Bear",
  "Beaver",
  "Bee",
  "Bison",
  "Boar",
  "Buffalo",
  "Butterfly",
  "Camel",
  "Capybara",
  "Caribou",
  "Cassowary",
  "Cat",
  "Caterpillar",
  "Cattle",
  "Chamois",
  "Cheetah",
  "Chicken",
  "Chimpanzee",
  "Chinchilla",
  "Chough",
  "Clam",
  "Cobra",
  "Cockroach",
  "Cod",
  "Cormorant",
  "Coyote",
  "Crab",
  "Crane",
  "Crocodile",
  "Crow",
  "Curlew",
  "Deer",
  "Dinosaur",
  "Dog",
  "Dogfish",
  "Dolphin",
  "Dotterel",
  "Dove",
  "Dragonfly",
  "Duck",
  "Dugong",
  "Dunlin",
  "Eagle",
  "Echidna",
  "Eel",
  "Eland",
  "Elephant",
  "Elk",
  "Emu",
  "Falcon",
  "Ferret",
  "Finch",
  "Fish",
  "Flamingo",
  "Fly",
  "Fox",
  "Frog",
  "Gaur",
  "Gazelle",
  "Gerbil",
  "Giraffe",
  "Gnat",
  "Gnu",
  "Goat",
  "Goldfinch",
  "Goldfish",
  "Goose",
  "Gorilla",
  "Goshawk",
  "Grasshopper",
  "Grouse",
  "Guanaco",
  "Gull",
  "Hamster",
  "Hare",
  "Hawk",
  "Hedgehog",
  "Heron",
  "Herring",
  "Hippopotamus",
  "Hornet",
  "Horse",
  "Human",
  "Hummingbird",
  "Hyena",
  "Ibex",
  "Ibis",
  "Jackal",
  "Jaguar",
  "Jay",
  "Jellyfish",
  "Kangaroo",
  "Kingfisher",
  "Koala",
  "Kookabura",
  "Kouprey",
  "Kudu",
  "Lapwing",
  "Lark",
  "Lemur",
  "Leopard",
  "Lion",
  "Llama",
  "Lobster",
  "Locust",
  "Loris",
  "Louse",
  "Lyrebird",
  "Magpie",
  "Mallard",
  "Manatee",
  "Mandrill",
  "Mantis",
  "Marten",
  "Meerkat",
  "Mink",
  "Mole",
  "Mongoose",
  "Monkey",
  "Moose",
  "Mosquito",
  "Mouse",
  "Mule",
  "Narwhal",
  "Newt",
  "Nightingale",
  "Octopus",
  "Okapi",
  "Opossum",
  "Oryx",
  "Ostrich",
  "Otter",
  "Owl",
  "Oyster",
  "Panther",
  "Parrot",
  "Partridge",
  "Peafowl",
  "Pelican",
  "Penguin",
  "Pheasant",
  "Pig",
  "Pigeon",
  "Pony",
  "Porcupine",
  "Porpoise",
  "Quail",
  "Quelea",
  "Quetzal",
  "Rabbit",
  "Raccoon",
  "Rail",
  "Ram",
  "Rat",
  "Raven",
  "Red deer",
  "Red panda",
  "Reindeer",
  "Rhinoceros",
  "Rook",
  "Salamander",
  "Salmon",
  "Sand Dollar",
  "Sandpiper",
  "Sardine",
  "Scorpion",
  "Seahorse",
  "Seal",
  "Shark",
  "Sheep",
  "Shrew",
  "Skunk",
  "Snail",
  "Snake",
  "Sparrow",
  "Spider",
  "Spoonbill",
  "Squid",
  "Squirrel",
  "Starling",
  "Stingray",
  "Stinkbug",
  "Stork",
  "Swallow",
  "Swan",
  "Tapir",
  "Tarsier",
  "Termite",
  "Tiger",
  "Toad",
  "Trout",
  "Turkey",
  "Turtle",
  "Viper",
  "Vulture",
  "Wallaby",
  "Walrus",
  "Wasp",
  "Weasel",
  "Whale",
  "Wildcat",
  "Wolf",
  "Wolverine",
  "Wombat",
  "Woodcock",
  "Woodpecker",
  "Worm",
  "Wren",
  "Yak",
  "Zebra",
];

export function generateUsername(seed: number) {
  const adjective = ADJECTIVE_LIST[seed % ADJECTIVE_LIST.length];
  const animal = ANIMAL_LIST[seed % ANIMAL_LIST.length];
  const capitalizedAdjective =
    adjective.charAt(0).toUpperCase() + adjective.slice(1);
  const capitalizedAnimal = animal.charAt(0).toUpperCase() + animal.slice(1);

  return `${capitalizedAdjective} ${capitalizedAnimal}`;
}
