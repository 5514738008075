import PlaintextTitleHeader from "../components/PlaintextTitleHeader";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserInfo } from "../hooks/useUserInfo";
import DraftSelectModal from "../components/DraftSelectModal";
import RoomBox from "../components/RoomBox";
import { Socket } from "socket.io-client";

export default function RoomPage() {
  const { roomID } = useParams();

  const [socket, setSocket] = useState<Socket | null>(null);

  const { isLoggedIn } = useUserInfo();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(`/room/${roomID}/draft/new`);
    }
  }, [isLoggedIn, navigate, roomID]);

  return (
    <div className="h-full">
      <PlaintextTitleHeader title="Joining room" />
      <DraftSelectModal
        open={true}
        setOpen={() => {}}
        roomID={roomID}
        dismissable={false}
      />
      <div className="flex flex-row" style={{ height: "calc(100% - 57px)" }}>
        <div
          className="flex flex-col justify-end"
          style={{ flex: 1, borderRightWidth: 2, borderColor: "gray" }}
        >
          <div>
            <RoomBox setSocket={setSocket} socket={socket} />
          </div>
        </div>
        <div style={{ flex: 2 }}></div>
      </div>
    </div>
  );
}
