import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import { EditorProvider } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import CharacterCount from "@tiptap/extension-character-count";
import { useEffect, useState, useMemo, useCallback } from "react";
import { LiteralTab } from "../utils/tabExtension";
import { gql, useMutation } from "@apollo/client";
import { useUserInfo } from "../hooks/useUserInfo";
import { encryptSymmetricString } from "../crypto/utils";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useDebouncedCallback } from "use-debounce";
import {
  setDraftHtml,
  setDraftChecksum,
  setOutOfSync,
} from "../redux/draftStore";
import { ActivityStatus } from "../gql/graphql";
import Placeholder from "@tiptap/extension-placeholder";
import dayjs from "dayjs";
import { Socket } from "socket.io-client";
import { setUnauthedDraftData } from "../utils/unauthedLocalStorage";

const extensions = [
  // @ts-ignore
  TextStyle.configure({ type: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    codeBlock: false,
    code: false,
  }),
  CharacterCount.configure(),
  LiteralTab.configure(),
  Placeholder.configure({
    placeholder: "Draft",
  }),
];

const UPDATE_DRAFT_HTML_MUTATION = gql`
  mutation updateDraftHtml(
    $draftID: String!
    $encryptedDraftHtml: String!
    $oldDraftChecksum: String
    $wordsAdded: Int!
    $wordsDeleted: Int!
    $timezone: String!
  ) {
    updateDraftHtml(
      request: {
        draftID: $draftID
        encryptedDraftHtml: $encryptedDraftHtml
        oldDraftChecksum: $oldDraftChecksum
        wordsAdded: $wordsAdded
        wordsDeleted: $wordsDeleted
        timezone: $timezone
      }
    ) {
      newDraftChecksum
    }
  }
`;

const INCREMENT_WORDS_WRITTEN_MUTATION = gql`
  mutation incrementWordsWritten($roomID: String!, $wordsWritten: Int!) {
    incrementWordsWritten(
      request: { roomID: $roomID, wordsWritten: $wordsWritten }
    ) {
      success
    }
  }
`;

export default function MainDocumentEditor(props: {
  currentActivityData: any;
  createActivityIfNoCurrent: any;
  userSettingsOrDefaults: any;
  socket: Socket | null;
}) {
  const {
    currentActivityData,
    createActivityIfNoCurrent,
    userSettingsOrDefaults,
    socket,
  } = props;
  const dispatch = useDispatch();
  const { roomID } = useParams();
  const [encryptedTooltipOpen, setEncryptedTooltipOpen] = useState(false);
  const [timeOfDayTooltipOpen, setTimeOfDayTooltipOpen] = useState(false);
  const currentHour = dayjs().hour();
  const timeOfDayString = useMemo(() => {
    if (currentHour < 1) {
      return "nightfall 🥱";
    } else if (currentHour < 4) {
      return "dead of night ☠️";
    } else if (currentHour < 7) {
      return "daybreak 🐓";
    } else if (currentHour < 11) {
      return "morning ☕";
    } else if (currentHour < 13) {
      return "midday 🕛";
    } else if (currentHour < 17) {
      return "afternoon ☀️";
    } else if (currentHour < 22) {
      return "evening 🌙";
    } else {
      return "nightfall 🥱";
    }
  }, [currentHour]);
  const [updateDraftHtml] = useMutation(UPDATE_DRAFT_HTML_MUTATION, {
    onCompleted: (data) => {
      if (data.updateDraftHtml) {
        dispatch(setDraftChecksum(data.updateDraftHtml.newDraftChecksum));
      }
    },
    onError: (e) => {
      const errorCodes = e.graphQLErrors.map((error) => error.extensions?.code);
      if (errorCodes.includes("CHECKSUM_MISMATCH")) {
        dispatch(setOutOfSync());
      }
    },
  });
  const [incrementWordsWritten] = useMutation(INCREMENT_WORDS_WRITTEN_MUTATION);
  const draftHtml = useSelector((state: RootState) => state.draft.draftHtml);
  const draftChecksum = useSelector(
    (state: RootState) => state.draft.draftChecksum
  );
  const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);
  const [requestPending, setRequestPending] = useState(false);
  const [draftDirty, setDraftDirty] = useState(false);
  const loaded = useSelector((state: RootState) => state.draft.loaded);
  const [localHtml, setLocalHtml] = useState(draftHtml);
  const { decryptionKey, isLoggedIn } = useUserInfo();
  const { draftID } = useParams();
  const recordingStatus = useSelector(
    (state: RootState) => state.activity.status
  );
  const isRecording = useMemo(
    () => recordingStatus === ActivityStatus.Active,
    [recordingStatus]
  );
  const isPaused = useMemo(
    () => recordingStatus === ActivityStatus.Paused,
    [recordingStatus]
  );
  // word count from the last time data was updated on client
  const [lastSubmittedWordCount, setLastSubmittedWordCount] = useState<
    number | null
  >(null);

  const [draftWordsAdded, setDraftWordsAdded] = useState<number>(0);
  const [draftWordsDeleted, setDraftWordsDeleted] = useState<number>(0);

  const { activityID, incrementActivity, unpauseActivity } =
    currentActivityData;

  const getCurrentTextWordCount = useCallback((editor: any) => {
    const text = editor.getText();
    return text.match(/\S+/g)?.length || 0;
  }, []);
  const [liveWordCount, setLiveWordCount] = useState<number>(0);

  const setCurrentTextWordCount = useCallback(
    (editor: any) => {
      const newWordCount = getCurrentTextWordCount(editor);
      setLastSubmittedWordCount(newWordCount);
    },
    [getCurrentTextWordCount]
  );

  const saveEncryptedDraftHtml = useCallback(async () => {
    if (decryptionKey && localHtml && draftID) {
      const encryptedDraftHtml = encryptSymmetricString({
        secretKey: decryptionKey,
        decryptedPayload: localHtml,
      });
      const curDraftWordsAdded = draftWordsAdded;
      const curDraftWordsDeleted = draftWordsDeleted;
      setDraftWordsAdded(0);
      setDraftWordsDeleted(0);
      await updateDraftHtml({
        variables: {
          draftID,
          encryptedDraftHtml,
          oldDraftChecksum: draftChecksum,
          wordsAdded: curDraftWordsAdded,
          wordsDeleted: curDraftWordsDeleted,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      });
      setRequestPending(false);
    }
  }, [
    decryptionKey,
    draftChecksum,
    draftID,
    draftWordsAdded,
    draftWordsDeleted,
    localHtml,
    updateDraftHtml,
  ]);

  // set to 100 to guard against useeffect retriggering
  const shorterDebouncedSaveEncryptedDraftHtml = useDebouncedCallback(
    saveEncryptedDraftHtml,
    100
  );

  useEffect(() => {
    if (draftDirty && !requestPending && isLoggedIn) {
      setRequestPending(true);
      setDraftDirty(false);
      shorterDebouncedSaveEncryptedDraftHtml();
    }
  }, [
    draftDirty,
    requestPending,
    shorterDebouncedSaveEncryptedDraftHtml,
    isLoggedIn,
  ]);

  const debouncedSaveEncryptedDraftHtml = useDebouncedCallback(
    saveEncryptedDraftHtml,
    500
  );

  const onUpdateCallback = useCallback(
    async (args: { editor: any; transaction: any }) => {
      // if we haven't loaded from server yet, don't update.
      if (!initialLoadCompleted) {
        return;
      }
      const { editor, transaction } = args;
      // This is very TipTap specific and not great, for some reason the update is firing even for mouse clicks
      // Alternatively we could compare curr/prev state but this might be faster computationally
      // since TipTap is already tracking things
      // 'replacearound' corresponds to a cursor click action (no actual addition/deletion)
      const charsChanged =
        transaction.docChanged &&
        transaction.steps.some((s: any) => s.jsonID !== "replaceAround");

      // No need to do anything if content has not changed
      if (!charsChanged) {
        return;
      }

      // Saves changes server-side
      const html = editor.getHTML();
      setLocalHtml(html);
      dispatch(setDraftHtml({ draftHtml: html }));

      if (lastSubmittedWordCount === null) {
        console.warn("No word count for some reason");
        return;
      }

      const newWordCount = getCurrentTextWordCount(editor);
      setLiveWordCount(newWordCount);

      const wordsAdded = Math.max(newWordCount - lastSubmittedWordCount, 0);
      const wordsDeleted = Math.max(lastSubmittedWordCount - newWordCount, 0);

      if (socket && roomID) {
        if (wordsAdded > 0) {
          socket.emit("awareness_activity", { type: "word_added", roomID });
        }
        if (wordsDeleted > 0) {
          socket.emit("awareness_activity", { type: "word_deleted", roomID });
        }
      }

      setDraftWordsAdded(draftWordsAdded + wordsAdded);
      setDraftWordsDeleted(draftWordsDeleted + wordsDeleted);
      if (!requestPending && !draftDirty && isLoggedIn) {
        setRequestPending(true);
        debouncedSaveEncryptedDraftHtml();
      } else if (requestPending && !draftDirty && isLoggedIn) {
        setDraftDirty(true);
      }

      // We don't need to do any additional updates
      if (lastSubmittedWordCount === newWordCount) {
        return;
      }

      const shouldCreateNewActivity = activityID === undefined;

      // Start a session automatically if we haven't recorded anything yet, and are now typing
      // But only if we're in auto-mode
      let shouldRecordDueToAutoRecord = false;
      if (userSettingsOrDefaults.autoRecord) {
        if (shouldCreateNewActivity && isLoggedIn) {
          await createActivityIfNoCurrent();
        } else if (isPaused && isLoggedIn) {
          await unpauseActivity();
        }
        shouldRecordDueToAutoRecord = true;
      }

      // Update activity if we're recording
      if ((isRecording || shouldRecordDueToAutoRecord) && isLoggedIn) {
        if (newWordCount > lastSubmittedWordCount) {
          // words added
          await incrementActivity({
            wordsAddedDelta: newWordCount - lastSubmittedWordCount,
          });
        } else if (newWordCount < lastSubmittedWordCount) {
          // words deleted
          await incrementActivity({
            wordsDeletedDelta: lastSubmittedWordCount - newWordCount,
          });
        }
      }

      if (newWordCount > lastSubmittedWordCount) {
        await incrementWordsWritten({
          variables: {
            roomID,
            wordsWritten: newWordCount - lastSubmittedWordCount,
          },
        });
      }
      // Update the last word count regardless
      setLastSubmittedWordCount(newWordCount);
    },
    [
      initialLoadCompleted,
      dispatch,
      isLoggedIn,
      lastSubmittedWordCount,
      getCurrentTextWordCount,
      socket,
      roomID,
      draftWordsAdded,
      draftWordsDeleted,
      requestPending,
      draftDirty,
      activityID,
      userSettingsOrDefaults.autoRecord,
      isRecording,
      debouncedSaveEncryptedDraftHtml,
      isPaused,
      createActivityIfNoCurrent,
      unpauseActivity,
      incrementActivity,
      incrementWordsWritten,
    ]
  );

  const debouncedOnUpdateCallback = useDebouncedCallback(onUpdateCallback, 100);

  useEffect(() => {
    if (loaded && !initialLoadCompleted) {
      setLocalHtml(draftHtml);
      setInitialLoadCompleted(true);
    }
  }, [draftHtml, initialLoadCompleted, loaded, localHtml]);

  useEffect(() => {
    if (!isLoggedIn) {
      setUnauthedDraftData(draftHtml);
    }
  }, [draftHtml, isLoggedIn]);

  if (!loaded) {
    return null;
  }

  return (
    <div className="flex flex-col h-full">
      <div
        className="h-full flex flex-col overflow-y-auto"
        id="document-editor-container" // this ID is used in index.css
        style={{ flex: 1, padding: 40 }}
      >
        <EditorProvider
          slotBefore={null}
          slotAfter={<div style={{ minHeight: 500, height: 500 }} />}
          extensions={extensions}
          content={draftHtml}
          editorProps={{
            attributes: {
              className: "h-full",
            },
            transformPastedHTML: (html: string) => {
              // any desired html transformations can go here
              return html;
            },
          }}
          onCreate={async ({ editor }) => {
            setLiveWordCount(getCurrentTextWordCount(editor));
            setCurrentTextWordCount(editor);
          }}
          onTransaction={({ transaction, editor }) => {
            setLiveWordCount(getCurrentTextWordCount(editor));
            debouncedOnUpdateCallback({ transaction, editor });
          }}
        >
          {null}
        </EditorProvider>
      </div>
      <div
        className="flex flex-row justify-end absolute items-center"
        style={{
          bottom: 0,
          right: 20,
          paddingBottom: 4,
          paddingTop: 4,
          paddingLeft: 4,
          backgroundColor: "white",
          cursor: "default",
        }}
      >
        <div
          onMouseEnter={() => {
            setTimeOfDayTooltipOpen(true);
          }}
          onMouseLeave={() => {
            setTimeOfDayTooltipOpen(false);
          }}
        >
          <p
            className="font-sans"
            style={{ fontSize: 12, color: "gray", cursor: "default" }}
          >
            {timeOfDayString}
          </p>
        </div>
        <div style={{ marginLeft: 4, marginRight: 4 }}>
          <p>·</p>
        </div>
        {timeOfDayTooltipOpen && (
          <div
            className="absolute"
            style={{
              bottom: 30,
              backgroundColor: "white",
              padding: 4,
              width: 380,
            }}
          >
            <p
              className="font-sans"
              style={{ fontSize: 12, color: "gray", textAlign: "right" }}
            >
              The time of day based on your timezone. You can check your profile
              to see your writing habits over time.
            </p>
          </div>
        )}
        {encryptedTooltipOpen && (
          <div
            className="absolute"
            style={{
              bottom: 30,
              backgroundColor: "white",
              padding: 4,
              width: 380,
            }}
          >
            <p
              className="font-sans"
              style={{ fontSize: 12, color: "gray", textAlign: "right" }}
            >
              Your document, notes, and title are completely private. No one
              besides you, not even the Draft Zero team, can read it.
            </p>
          </div>
        )}
        <p
          className="font-sans"
          style={{ fontSize: 12, color: "gray", cursor: "default" }}
          onMouseEnter={() => {
            setEncryptedTooltipOpen(true);
          }}
          onMouseLeave={() => {
            setEncryptedTooltipOpen(false);
          }}
        >
          encrypted
        </p>
        <div style={{ marginLeft: 4, marginRight: 4 }}>
          <p>·</p>
        </div>
        <p className="font-sans" style={{ fontSize: 12, color: "gray" }}>
          {liveWordCount} word{liveWordCount !== 1 ? "s" : ""}
        </p>
      </div>
    </div>
  );
}
