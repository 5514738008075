import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
} from "@nextui-org/react";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Notification as GqlNotification,
  NotificationType,
} from "../gql/graphql";
import { CloseCircle, Notification, TickCircle } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { useUserInfo } from "../hooks/useUserInfo";

const GET_NOTIFICATIONS = gql`
  query GetNotifications {
    getNotifications {
      notificationID
      read
      createdAt
      type
      metadata
    }
  }
`;

const MARK_ALL_NOTIFICATIONS_AS_READ = gql`
  mutation MarkAllNotificationsAsRead {
    markAllNotificationsRead {
      success
    }
  }
`;

const ACCEPT_FRIEND_REQUEST = gql`
  mutation AcceptFriendRequest($username: String!) {
    acceptFriendRequest(request: { username: $username }) {
      success
    }
  }
`;

const REJECT_FRIEND_REQUEST = gql`
  mutation RejectFriendRequest($username: String!) {
    declineFriendRequest(request: { username: $username }) {
      success
    }
  }
`;

type FollowRequestMetadata = {
  username: string;
};

function generateNotificationMessage({
  notification,
}: {
  notification: GqlNotification;
}) {
  if (notification.type === NotificationType.FollowRequest) {
    return `${notification.metadata.username} wants to follow you`;
  } else if (notification.type === NotificationType.YouAcceptedFollowRequest) {
    return `${notification.metadata.username} is now following you`;
  } else if (
    notification.type === NotificationType.YourFollowRequestWasAccepted
  ) {
    return `You are now following ${notification.metadata.username}`;
  } else if (notification.type === NotificationType.ActivityLike) {
    return `${notification.metadata.username} liked your post`;
  } else if (notification.type === NotificationType.ActivityComment) {
    return `${notification.metadata.username} commented on your post`;
  } else if (notification.type === NotificationType.CommentLike) {
    return `${notification.metadata.username} liked your comment`;
  } else if (notification.type === NotificationType.CommentReply) {
    return `${notification.metadata.username} replied to your comment: "${notification.metadata.comment}"`;
  }
  return "";
}

function getURLFromNotification({
  notification,
  selfUsername,
}: {
  notification: GqlNotification;
  selfUsername: string;
}) {
  if (notification.type === NotificationType.FollowRequest) {
    return `/writer/${notification.metadata.username}`;
  } else if (notification.type === NotificationType.YouAcceptedFollowRequest) {
    return `/writer/${notification.metadata.username}`;
  } else if (
    notification.type === NotificationType.YourFollowRequestWasAccepted
  ) {
    return `/writer/${notification.metadata.username}`;
  } else if (notification.type === NotificationType.ActivityLike) {
    return `/session/${notification.metadata.activityID}`;
  } else if (notification.type === NotificationType.ActivityComment) {
    return `/session/${notification.metadata.activityID}`;
  } else if (notification.type === NotificationType.CommentLike) {
    return `/session/${notification.metadata.activityID}`;
  } else if (notification.type === NotificationType.CommentReply) {
    return `/session/${notification.metadata.activityID}`;
  }
  return "";
}

function GenerateEndContent({
  notification,
  updateLocalNotificationByIndex,
  index,
  bellRef,
}: {
  notification: GqlNotification;
  updateLocalNotificationByIndex: ({
    index,
    updatedNotification,
  }: {
    index: number;
    updatedNotification: GqlNotification | null;
  }) => void;
  index: number;
  bellRef: React.RefObject<HTMLDivElement>;
}) {
  const [acceptHovered, setAcceptHovered] = useState(false);
  const [rejectHovered, setRejectHovered] = useState(false);
  const [acceptFriendRequestMutation] = useMutation(ACCEPT_FRIEND_REQUEST);
  const [rejectFriendRequestMutation] = useMutation(REJECT_FRIEND_REQUEST);
  if (notification.type === NotificationType.FollowRequest) {
    return (
      <div className="flex items-center flex-row" style={{ marginLeft: 20 }}>
        <Button
          className="flex flex-col items-center justify-center"
          style={{
            minWidth: 20,
            height: 20,
            borderRadius: 20,
          }}
          isIconOnly
          color="default"
          variant="light"
          onMouseEnter={() => setAcceptHovered(true)}
          onMouseLeave={() => setAcceptHovered(false)}
          onClick={async (e) => {
            updateLocalNotificationByIndex({
              index,
              updatedNotification: {
                type: NotificationType.YouAcceptedFollowRequest,
                read: true,
                createdAt: notification.createdAt,
                metadata: notification.metadata,
                notificationID: notification.notificationID,
              },
            });
            acceptFriendRequestMutation({
              variables: {
                username: (notification.metadata as FollowRequestMetadata)
                  .username,
              },
            });
            setTimeout(() => {
              bellRef.current?.click();
            }, 0);
          }}
        >
          <TickCircle
            size={20}
            variant="Bold"
            color={acceptHovered ? "#12A150" : "#45D483"}
          />
        </Button>
        <Button
          className="flex flex-col items-center justify-center"
          style={{
            minWidth: 20,
            height: 20,
            borderRadius: 20,
          }}
          isIconOnly
          color="default"
          variant="light"
          onMouseEnter={() => setRejectHovered(true)}
          onMouseLeave={() => setRejectHovered(false)}
          onClick={async (e) => {
            updateLocalNotificationByIndex({
              index,
              updatedNotification: null,
            });
            await rejectFriendRequestMutation({
              variables: {
                username: (notification.metadata as FollowRequestMetadata)
                  .username,
              },
            });
            setTimeout(() => {
              bellRef.current?.click();
            }, 0);
          }}
        >
          <CloseCircle
            size={20}
            variant="Bold"
            color={rejectHovered ? "red" : "#ff7e75"}
          />
        </Button>
      </div>
    );
  }
  return null;
}

function NotificationItem({
  notification,
  updateLocalNotificationByIndex,
  index,
}: {
  notification: GqlNotification;
  updateLocalNotificationByIndex: ({
    index,
    updatedNotification,
  }: {
    index: number;
    updatedNotification: GqlNotification | null;
  }) => void;
  index: number;
}) {
  return (
    <div className="flex items-center" style={{ textWrap: "wrap" }}>
      <p
        className="ml-2"
        style={{
          fontSize: 12,
          fontWeight: notification.read ? undefined : "bold",
        }}
      >
        {generateNotificationMessage({ notification })}
      </p>
    </div>
  );
}

function NotificationBell() {
  const navigate = useNavigate();
  const { data: notificationsData } = useQuery(GET_NOTIFICATIONS, {
    fetchPolicy: "no-cache",
  });
  const [localNotificationsData, setLocalNotificationsData] = useState<{
    notifications: GqlNotification[];
    unreadCount: number;
  }>({ notifications: [], unreadCount: 0 });
  const { username } = useUserInfo();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (notificationsData) {
      const notifications =
        notificationsData.getNotifications as GqlNotification[];
      const unreadCount = notifications.filter(
        (notification) => !notification.read
      );
      setLocalNotificationsData({
        notifications,
        unreadCount: unreadCount.length,
      });
    }
  }, [notificationsData]);

  const [markAllNotificationsAsReadMutation] = useMutation(
    MARK_ALL_NOTIFICATIONS_AS_READ
  );

  const markAllNotificationsAsRead = useCallback(async () => {
    await markAllNotificationsAsReadMutation();
    setLocalNotificationsData({
      ...localNotificationsData,
      unreadCount: 0,
    });
  }, [localNotificationsData, markAllNotificationsAsReadMutation]);

  const markLocalNotificationsRead = useCallback(() => {
    setLocalNotificationsData({
      ...localNotificationsData,
      notifications: localNotificationsData.notifications.map(
        (notification) => ({
          ...notification,
          read: true,
        })
      ),
    });
  }, [localNotificationsData]);

  const updateLocalNotificationByIndex = useCallback(
    ({
      index,
      updatedNotification,
    }: {
      index: number;
      updatedNotification: GqlNotification | null;
    }) => {
      if (updatedNotification) {
        setLocalNotificationsData({
          ...localNotificationsData,
          notifications: localNotificationsData.notifications.map(
            (notification, i) =>
              i === index ? updatedNotification : notification
          ),
        });
      } else {
        setLocalNotificationsData({
          ...localNotificationsData,
          notifications: localNotificationsData.notifications.filter(
            (_, i) => i !== index
          ),
        });
      }
    },
    [localNotificationsData]
  );

  if (!username) {
    return null;
  }

  return (
    <Dropdown
      onOpenChange={(isOpen) => {
        if (isOpen && localNotificationsData.unreadCount > 0) {
          markAllNotificationsAsRead();
        }
        if (!isOpen) {
          markLocalNotificationsRead();
        }
      }}
    >
      <DropdownTrigger>
        <div
          style={{
            marginRight: 20,
            position: "relative",
            cursor: "pointer",
          }}
          ref={ref}
        >
          <Notification
            color={localNotificationsData.unreadCount > 0 ? "red" : "black"}
          />
          {localNotificationsData.unreadCount > 0 && (
            <div
              className="font-sans"
              style={{
                position: "absolute",
                top: -4,
                right: -4,
                fontSize: 10,
                backgroundColor: "red",
                color: "white",
                borderRadius: "50%",
                width: 15,
                height: 15,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {localNotificationsData.unreadCount}
            </div>
          )}
        </div>
      </DropdownTrigger>
      <DropdownMenu
        aria-labelledby="notification-menu"
        className="font-sans"
        style={{
          width: 300,
        }}
        itemClasses={{
          base: ["data-[hover=true]:bg-default-100"],
        }}
        emptyContent="No notifications."
        onAction={(key) => {
          const matchingNotification =
            localNotificationsData.notifications.find(
              (notification) => notification.notificationID === key
            );
          if (matchingNotification) {
            navigate(
              getURLFromNotification({
                notification: matchingNotification,
                selfUsername: username,
              })
            );
          }
        }}
      >
        {localNotificationsData.notifications &&
          localNotificationsData.notifications.map(
            (notification: any, index: number) =>
              index !== localNotificationsData.notifications.length - 1 ? (
                <DropdownSection
                  showDivider={true}
                  key={`section-${notification.notificationID}`}
                >
                  <DropdownItem
                    textValue={`notification-item-${index}`}
                    key={notification.notificationID}
                    style={{
                      borderColor: "#d4d4d8",
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                    endContent={
                      <GenerateEndContent
                        notification={notification}
                        updateLocalNotificationByIndex={
                          updateLocalNotificationByIndex
                        }
                        index={index}
                        bellRef={ref}
                      />
                    }
                  >
                    <NotificationItem
                      notification={notification}
                      updateLocalNotificationByIndex={
                        updateLocalNotificationByIndex
                      }
                      index={index}
                    />
                  </DropdownItem>
                </DropdownSection>
              ) : (
                <DropdownItem
                  textValue={`notification-item-${index}`}
                  key={notification.notificationID}
                  style={{
                    borderColor: "#d4d4d8",
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                  endContent={
                    <GenerateEndContent
                      notification={notification}
                      updateLocalNotificationByIndex={
                        updateLocalNotificationByIndex
                      }
                      index={index}
                      bellRef={ref}
                    />
                  }
                >
                  <NotificationItem
                    notification={notification}
                    updateLocalNotificationByIndex={
                      updateLocalNotificationByIndex
                    }
                    index={index}
                  />
                </DropdownItem>
              )
          )}
      </DropdownMenu>
    </Dropdown>
  );
}

export default NotificationBell;
