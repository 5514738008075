import MainDocumentEditor from "../components/MainDocumentEditor";
import DocumentEditorHeader from "../components/DocumentEditorHeader";
import NoteEditor from "../components/NoteEditor";
import { useCurrentDraft } from "../hooks/useCurrentDraft";
import { useEffect, useMemo, useState } from "react";
import { resetDraftState } from "../redux/draftStore";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Button, Modal, ModalContent, ModalHeader } from "@nextui-org/react";
import { useCreateActivity } from "../hooks/useCreateActivity";
import { useCurrentActivity } from "../hooks/useCurrentActivity";
import { Panel, PanelGroup } from "react-resizable-panels";
import ResizeHandle from "../components/ResizeHandle";
import { useUserSettings } from "../hooks/useUserSettings";
import { gql, useMutation } from "@apollo/client";
import { useDebouncedCallback } from "use-debounce";
import { useNavigate, useParams } from "react-router-dom";
import RoomBox from "../components/RoomBox";
import { Socket } from "socket.io-client";
import PromptEditor from "../components/PromptEditor";
import { ChatBox } from "../components/ChatBox";
import { RoomPresets } from "../gql/graphql";
import { useUserInfo } from "../hooks/useUserInfo";
import UnauthenticatedDocumentEditorHeader from "../components/UnauthenticatedDocumentEditorHeader";

const UPDATE_NOTE_DRAFT_SPLIT = gql`
  mutation updateNoteDraftSplit($noteDraftSplit: Float!, $draftID: String!) {
    updateDraftSplitFraction(
      request: { noteDraftSplit: $noteDraftSplit, draftID: $draftID }
    ) {
      success
    }
  }
`;

function EditorPage() {
  const dispatch = useDispatch();
  const { userSettingsOrDefaults, userSettingsLoading } = useUserSettings();
  const [updateNoteDraftSplit] = useMutation(UPDATE_NOTE_DRAFT_SPLIT);

  const { roomID, draftID } = useParams();
  const { isLoggedIn } = useUserInfo();
  const navigate = useNavigate();

  const isPresetRoom = useMemo(() => {
    return roomID === RoomPresets.TheEveryoneRoom;
  }, [roomID]);
  const [socket, setSocket] = useState<Socket | null>(null);

  const debouncedUpdateNoteDraftSplit = useDebouncedCallback(
    async (updatedNoteDraftSplit: number) => {
      await updateNoteDraftSplit({
        variables: {
          noteDraftSplit: updatedNoteDraftSplit,
          draftID: draftID,
        },
      });
    },
    300
  );
  useCurrentDraft(); // load draft data into redux store
  const outOfSync = useSelector((state: RootState) => state.draft.outOfSync);
  const noteDraftSplit = useSelector(
    (state: RootState) => state.draft.noteDraftSplit
  );
  useEffect(() => {
    return () => {
      dispatch(resetDraftState());
      resetDraftState();
    };
  }, [dispatch]);
  const currentActivityData = useCurrentActivity();
  const { createActivityIfNoCurrent } = useCreateActivity();

  useEffect(() => {
    if (!roomID) {
      navigate("/");
    }
  }, [roomID, navigate]);

  useEffect(() => {
    if (!isLoggedIn && draftID !== "new") {
      navigate(`/room/${roomID}/draft/new`);
    }
  }, [isLoggedIn, draftID, navigate, roomID]);

  return !userSettingsLoading && noteDraftSplit !== null ? (
    <div className="h-full flex-col flex overflow-hidden">
      {isLoggedIn ? (
        <DocumentEditorHeader socket={socket} />
      ) : (
        <UnauthenticatedDocumentEditorHeader socket={socket} />
      )}
      <Modal isOpen={outOfSync} isDismissable={false}>
        <ModalHeader>Draft out of sync</ModalHeader>
        <ModalContent>
          <div className="font-sans flex-col" style={{ padding: 20 }}>
            <p>
              Your draft is out of sync with the server. Reload the page to
              resync.
            </p>
            <Button
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload
            </Button>
          </div>
        </ModalContent>
      </Modal>
      <PanelGroup direction="horizontal">
        <Panel
          defaultSize={noteDraftSplit * 100}
          className="h-full flex flex-col"
          onResize={(updatedPercentage) => {
            if (isLoggedIn) {
              debouncedUpdateNoteDraftSplit(updatedPercentage / 100);
            }
          }}
          minSize={10}
        >
          {!isPresetRoom && <PromptEditor socket={socket} />}
          <NoteEditor />
          <RoomBox socket={socket} setSocket={setSocket} />
        </Panel>
        <ResizeHandle />
        <Panel minSize={30}>
          <div className="h-full flex flex-col">
            <MainDocumentEditor
              socket={socket}
              currentActivityData={currentActivityData}
              createActivityIfNoCurrent={createActivityIfNoCurrent}
              userSettingsOrDefaults={userSettingsOrDefaults}
            />
          </div>
        </Panel>
      </PanelGroup>
      <div
        className="absolute overflow-hidden"
        style={{ bottom: 50, right: 0 }}
      >
        <ChatBox socket={socket} />
      </div>
    </div>
  ) : null;
}

export default EditorPage;
